import { useState, markRaw } from "#imports";

import iconFlagUK from "~icons/hh-icons/icon-flag-uk";
import iconFlagTH from "~icons/hh-icons/icon-flag-th";

export const defaultLang = "en";
export const useLang = () => useState<string>("lang", () => defaultLang);

export function getLanguage() {
  return useLang().value;
}

export function getIcon(lang: string) {
  return lang === "en" ? markRaw(iconFlagUK) : markRaw(iconFlagTH);
}

export function setLanguage(lang: string) {
  useLang().value = lang;
}

export function unusedLanguage() {
  return languageOption.filter((langOption) => useLang().value !== langOption);
}

export function changeLanguage(lang: string) {
  window.location.href = lang;
}

export const languageOption = ["en", "th"];
